import {
  GUESTS_MANAGEMENT_DELETE_FUTURE_GUEST,
  GUESTS_MANAGEMENT_DELETE_PAST_GUEST,
  GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS,
  GUESTS_MANAGEMENT_GET_ALL_GUARD_FUTURE_GUESTS,
  GUESTS_MANAGEMENT_GET_ALL_GUARD_PAST_GUESTS,
  GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS,
  GUESTS_MANAGEMENT_GUARD_SET_ACTIVE_PAGE,
  GUESTS_MANAGEMENT_GUARD_SET_ARRIVAL_DATE_FILTER,
  GUESTS_MANAGEMENT_GUARD_SET_BUILDING_FILTER,
  GUESTS_MANAGEMENT_GUARD_SET_COMPANY_NAME_FILTER,
  GUESTS_MANAGEMENT_GUARD_SET_COUNT_TO_SHOW,
  GUESTS_MANAGEMENT_GUARD_SET_FULL_NAME_FILTER,
  GUESTS_MANAGEMENT_GUARD_SET_LICENSE_NUMBER_FILTER,
  GUESTS_MANAGEMENT_GUARD_SET_PAGES,
  GUESTS_MANAGEMENT_GUARD_SET_PHONE_FILTER,
  GUESTS_MANAGEMENT_MY_GUESTS_SET_ACTIVE_PAGE,
  GUESTS_MANAGEMENT_MY_GUESTS_SET_ARRIVAL_DATE_FILTER,
  GUESTS_MANAGEMENT_MY_GUESTS_SET_COUNT_TO_SHOW,
  GUESTS_MANAGEMENT_MY_GUESTS_SET_FULL_NAME_FILTER,
  GUESTS_MANAGEMENT_MY_GUESTS_SET_PAGES,
  GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_ACTIVE_PAGE,
  GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_COUNT_TO_SHOW,
  GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_FULL_NAME_FILTER,
  GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_PAGES,
  GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_SELECTED_IDS,
  GUESTS_MANAGEMENT_SET_LICENSE_NUMBER_FILTER,
  GUESTS_MANAGEMENT_SET_PHONE_FILTER,
  GUESTS_MANAGEMENT_SET_REPEATABLE_GUESTS,
  GuestsManagementAction,
} from './guestsManagementActionTypes';
import { GuestsManagementState } from './guestsManagementTypes';

const initialState: GuestsManagementState = {
  futureGuests: [],
  pastGuests: [],
  pagination: {
    activePage: 1,
    countToShow: 10,
    pages: 1,
  },
  guardFutureGuests: [],
  guardPastGuests: [],
  guardGuestPagination: {
    activePage: 1,
    countToShow: 10,
    pages: 1,
  },
  guardGuestFilters: {
    fullName: '',
    licenseNumber: '',
    phone: '',
    companyId: { value: '', label: '' },
    buildingId: { value: '', label: '' },
    arrivalDate: [],
  },
  guestFilters: {
    fullName: '',
    arrivalDate: [],
    phone: '',
    licenseNumber: '',
  },
  repeatableGuests: {
    pagination: {
      activePage: 1,
      countToShow: 10,
      pages: 1,
    },
    filters: {
      fullName: '',
    },
    guests: [],
    selectedIds: [],
  },
};

export const GUESTS_MANAGEMENT_NAMESPACE = 'GUESTS_MANAGEMENT_NAMESPACE';

function guestsManagementReducer(
  state = initialState,
  action: GuestsManagementAction
): GuestsManagementState {
  switch (action.type) {
    case GUESTS_MANAGEMENT_GUARD_SET_COUNT_TO_SHOW:
      return {
        ...state,
        guardGuestPagination: {
          ...state.guardGuestPagination,
          countToShow: action.count,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_PAGES:
      return {
        ...state,
        guardGuestPagination: {
          ...state.guardGuestPagination,
          pages: action.pages,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_ACTIVE_PAGE:
      return {
        ...state,
        guardGuestPagination: {
          ...state.guardGuestPagination,
          activePage: action.page,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_FULL_NAME_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          fullName: action.fullName,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_COMPANY_NAME_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          companyId: action.companyId,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_LICENSE_NUMBER_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          licenseNumber: action.licenseNumber,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_BUILDING_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          buildingId: action.buildingId,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_ARRIVAL_DATE_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          arrivalDate: action.arrivalDate,
        },
      };
    case GUESTS_MANAGEMENT_MY_GUESTS_SET_ARRIVAL_DATE_FILTER:
      return {
        ...state,
        guestFilters: {
          ...state.guestFilters,
          arrivalDate: action.arrivalDate,
        },
      };
    case GUESTS_MANAGEMENT_MY_GUESTS_SET_FULL_NAME_FILTER:
      return {
        ...state,
        guestFilters: {
          ...state.guestFilters,
          fullName: action.fullName,
        },
      };
    case GUESTS_MANAGEMENT_MY_GUESTS_SET_COUNT_TO_SHOW:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          countToShow: action.count,
        },
      };
    case GUESTS_MANAGEMENT_MY_GUESTS_SET_PAGES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pages: action.pages,
        },
      };
    case GUESTS_MANAGEMENT_MY_GUESTS_SET_ACTIVE_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          activePage: action.page,
        },
      };

    case GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS:
      return {
        ...state,
        futureGuests: action.payload,
      };
    case GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS:
      return {
        ...state,
        pastGuests: action.payload,
      };
    case GUESTS_MANAGEMENT_GET_ALL_GUARD_FUTURE_GUESTS:
      return {
        ...state,
        guardFutureGuests: action.payload,
      };
    case GUESTS_MANAGEMENT_GET_ALL_GUARD_PAST_GUESTS:
      return {
        ...state,
        guardPastGuests: action.payload,
      };
    case GUESTS_MANAGEMENT_DELETE_FUTURE_GUEST:
      return {
        ...state,
        futureGuests: state.futureGuests.filter(
          (futureGuest) => futureGuest.id !== action.id
        ),
      };
    case GUESTS_MANAGEMENT_DELETE_PAST_GUEST:
      return {
        ...state,
        pastGuests: state.pastGuests.filter(
          (pastGuest) => pastGuest.id !== action.id
        ),
      };
    case GUESTS_MANAGEMENT_SET_LICENSE_NUMBER_FILTER:
      return {
        ...state,
        guestFilters: {
          ...state.guestFilters,
          licenseNumber: action.licenseNumber,
        },
      };
    case GUESTS_MANAGEMENT_SET_REPEATABLE_GUESTS:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          guests: action.guests,
        },
      };
    case GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_ACTIVE_PAGE:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          pagination: {
            ...state.repeatableGuests.pagination,
            activePage: action.page,
          },
        },
      };
    case GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_COUNT_TO_SHOW:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          pagination: {
            ...state.repeatableGuests.pagination,
            countToShow: action.count,
          },
        },
      };
    case GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_PAGES:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          pagination: {
            ...state.repeatableGuests.pagination,
            pages: action.pages,
          },
        },
      };
    case GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_FULL_NAME_FILTER:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          filters: {
            ...state.repeatableGuests.filters,
            fullName: action.fullName,
          },
        },
      };
    case GUESTS_MANAGEMENT_SET_PHONE_FILTER:
      return {
        ...state,
        guestFilters: {
          ...state.guestFilters,
          phone: action.phone,
        },
      };
    case GUESTS_MANAGEMENT_GUARD_SET_PHONE_FILTER:
      return {
        ...state,
        guardGuestFilters: {
          ...state.guardGuestFilters,
          phone: action.phone,
        },
      };
    case GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_SELECTED_IDS:
      return {
        ...state,
        repeatableGuests: {
          ...state.repeatableGuests,
          selectedIds: action.selectedIds,
        },
      };
    default:
      return state;
  }
}

export default guestsManagementReducer;
